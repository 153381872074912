// transform
.transform-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .margin-center {
    display: block;
    margin: 0 auto;
  }
  
  // position
  .position {
    &-rel {
      position: relative;
    }
    &-abs {
      position: absolute;
    }
  }
  
  // display
  .d {
    &-none {
      display: none;
    }
    &-block {
      display: block;
    }
    &-flex {
      display: flex;
    }
  }
  
  // display-flex
  .flex {
    &-col {
      display: flex;
      flex-direction: column;
    }
  
    &-grow-1 {
      flex-grow: 1;
    }
  
    &-wrap {
      flex-wrap: wrap;
    }
  
    &-center {
      display: flex;
      align-items: center;
      justify-content: center;
  
      &--ver {
        display: flex;
        align-items: center;
      }
  
      &--hor {
        display: flex;
        justify-content: center;
      }
  
      &-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
  
      &-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
  
      &-start {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
  
      &-end {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  
  .flex-dir {
    &-col {
      flex-direction: column;
    }
    &-row-reverse {
      flex-direction: row-reverse;
    }
  }
  
  // align-items
  .align-i {
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
  }
  
  // justify content
  .jus-content {
    &-around {
      justify-content: space-around;
    }
    &-between {
      justify-content: space-between;
    }
    &-end {
      justify-content: flex-end;
    }
    &-center {
      justify-content: center;
    }
  }
  
  // width, height
  .w-100 {
    width: 100% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .h-100vh {
    height: 100vh;
  }
  
  .w-100vw {
    width: 100vw;
  }
  
  .w-50 {
    width: 50%;
  }
  
  .max {
    &-w-100 {
      max-width: 100%;
    }
    &-h-100 {
      max-height: 100%;
    }
  }
  
  // spacing 0.2rem -> 2 rem
  @for $i from 1 to 11 {
    .m {
      &-#{$i} {
        margin: #{$i * 0.2}rem !important;
      }
      &t-#{$i} {
        margin-top: #{$i * 0.2}rem !important;
      }
      &b-#{$i} {
        margin-bottom: #{$i * 0.2}rem !important;
      }
      &l-#{$i} {
        margin-left: #{$i * 0.2}rem !important;
      }
      &r-#{$i} {
        margin-right: #{$i * 0.2}rem !important;
      }
      &x-#{$i} {
        margin-left: #{$i * 0.2}rem !important;
        margin-right: #{$i * 0.2}rem !important;
      }
      &y-#{$i} {
        margin-top: #{$i * 0.2}rem !important;
        margin-bottom: #{$i * 0.2}rem !important;
      }
    }
    .p {
      &-#{$i} {
        padding: #{$i * 0.2}rem !important;
      }
      &t-#{$i} {
        padding-top: #{$i * 0.2}rem !important;
      }
      &b-#{$i} {
        padding-bottom: #{$i * 0.2}rem !important;
      }
      &l-#{$i} {
        padding-left: #{$i * 0.2}rem !important;
      }
      &r-#{$i} {
        padding-right: #{$i * 0.2}rem !important;
      }
      &x-#{$i} {
        padding-left: #{$i * 0.2}rem !important;
        padding-right: #{$i * 0.2}rem !important;
      }
      &y-#{$i} {
        padding-top: #{$i * 0.2}rem !important;
        padding-bottom: #{$i * 0.2}rem !important;
      }
    }
  }
  
  // margin auto, 0
  .m {
    &-x-auto {
      margin-left: auto;
      margin-right: auto;
    }
    &-0-auto {
      margin: 0 auto !important;
    }
    &-auto-0 {
      margin: auto 0 !important;
    }
    &-auto {
      margin: auto !important;
    }
    &-0 {
      margin: 0 !important;
    }
  }
  