
.cards
{
  width: 100%;
  /* height: 100%; */
  height: 55%;
  cursor: pointer;
  margin: auto;
  padding-top: 20px;

  /*transition effects */
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cards.flipped 
{
  -webkit-transform: rotateY( 180deg );
  -moz-transform: rotateY( 180deg );
  -o-transform: rotateY( 180deg );
  transform: rotateY( 180deg );
}

.cards .front,
.cards .back {
  display: block;
  width: 100%;
  height: 45vh;
  /* height: 325px; */
  color: rgb(0, 0, 0);
  /* text-align: center; */
  font-size: 4em;
  position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  /* -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26); */
  border-radius: 10px;
  /* box-shadow: rgb(188 194 207) 0px 4px 0px; */
}

p {
  font-size: 16px;
  margin-top: 25px;
}

.cardsImg {
  flex-shrink: 0;
  /* background-image: linear-gradient(147deg, #000 0%, #000 74%); */
  /* box-shadow: 0px 3px 10px 1px rgb(252 56 56 / 20%); */
  border-radius: 10px;
  overflow: hidden;
  margin: 0 15px 0 15px;
  border: 1px solid rgb(226, 226, 226);
  width: 220px;
  height: auto;
}

.cards .back {
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 16px;
  line-height: 25px;
  margin: auto;

  -webkit-transform: rotateY( 180deg );
  -moz-transform: rotateY( 180deg );
  -o-transform: rotateY( 180deg );
  transform: rotateY( 180deg );
}

.cardContainer
{
  padding: 0px 25px 0 25px !important;
  height: 50vh;
  margin: 20px 0 10px 0px;
}

/*Colors for front and back applied here*/
.cardContainer .cards .front  {
  /* background: linear-gradient(147deg, rgb(230 255 229) 0%, rgb(236 255 241) 50%); */
  background-color: #fff;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(243, 243, 243); */
}

.cardContainer .cards .back {
  /* background: linear-gradient(147deg, rgb(236 239 255) 0%, rgb(243 249 255) 50%); */
  background-color: #fff;
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(243, 243, 243); */
}

h3.cardTitle {
  line-height: 1.2em;
  margin-top: 8%;
  font-weight: 600;
}
.content h3.cardTitle
{
  margin-top: 0%;
}

.cards__words {
  margin: auto;
  font-family: 'Noto Serif KR', serif;
  font-weight: 700;
  /* color: rgb(194, 61, 9); */
  color: rgb(0, 0, 0);
  font-size: 45px;
  /* text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black; */
}

.cards__words__group {
  text-align: center;
  width: 100%;
}

.content {
  font-weight: 100;
  text-align: left;
  width: 100%;
}

.cards__meaning__group
{
  /* display: flex; */
  padding: 10px;
  margin: 0 10px 0 10px;
  box-shadow: rgb(188 194 207) 0px 1px 0px;
}

.cards__meaning__kr {
  font-family: 'Noto Serif KR', serif;
  color: rgb(41 55 73);
  padding-right: 15px;
  font-size: 30px;
  font-weight: 600;
}
.cards__meaning__vn {
  font-family: 'Itim', cursive;
  color: rgb(41 55 73);
  margin-top: 5px;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

h5.cards__subtitle
{
  font-family: 'Source Sans Pro';
  padding-top: 15px;
  color: #873800;
  font-size: 22px;
  /* font-style: italic; */
  font-weight: 600;
}

.cards__subtitle
{
  font-family: 'Noto Serif KR', serif;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: rgb(255, 0, 0);
  padding-bottom: 10px;
}

.cards__example__kr {
  font-family: 'Noto Serif KR', serif;
  font-size: 21px;
  color: #ff0000;
  padding-bottom: 5px;
  font-weight: 500;
  padding-right: 10px;
}

.cards__example__vn
{
  font-size: 21px;
  color: #00118b;
  font-weight: 600;
}

.cards__example__split
{
  display: flex;
  justify-content: center;
}

.cards__button-wapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cards__button-wapper__cards
{
  display: block;
  width: calc(100% - 30px);
  position: absolute;
  padding: 5px;
  bottom: 0;
}

.cards__button-wapper__prev
{
  float: left;
  color: #000 !important;
  background: #ffa464 !important;
  box-shadow: rgb(0 0 0 / 10%) 0px -2px 0px inset;
  border-color: #ededed !important;
  min-width: 65px;
}

.cards__button-wapper__next
{
  float: right;
  color: #000 !important;
  background: #ffa464 !important;
  box-shadow: rgb(0 0 0 / 10%) 0px -2px 0px inset;
  border-color: #ededed !important;
  min-width: 65px;
}

.button__group {
  display: flex;
  
}

.button__group__button
{
  height: 50px;
  margin: 20px 15px 20px 0;
}

.cards__process
{
  margin-left: 15px;
  margin-right: 15px;
}

.header__custom
{
  padding: 15px 0 15px 0;
  margin: 15px;
  box-shadow: rgb(188 194 207) 0px 3px 0px;
  color: #e15d00;
}

.header__custom h4 {
  font-weight: 600;
}

.header__custom.header__courses {
  padding: 15px 0 10px 0 !important;
  margin: 15px 0 15px 0 !important;
}

.cards_example__group
{
  padding: 25px 10px 25px 10px;
  margin: 0px 10px 0px 10px;
  /* box-shadow: rgb(188 194 207) 0px 1px 0px; */
  border: 1px solid #e5e4e4;
}


.xxxm{
  background: rgb(188 194 207);
  height: 3px;
  width: 100%;
  margin-bottom: 15px;
}


/* tu vung da hoc/chua hoc */
.words_statistic__group
{
  display: flex;
  justify-content: space-between;
  height: 50px;
  background-color: #fdfdfd;
  width: 70%;
  padding: 15px;
  box-shadow: rgb(188 194 207) 0px 1px 0px;
  margin: 15px 30px 15px 30px;
}

.words_statistic__group.admin
{
  margin: 10px 0 10px 0;
  height: 70px;
  width: 100%;
  justify-content: space-between;
}

.words_statistic__title,
.words_statistic__meaning
{
  /* font-family: 'Noto Serif KR', serif; */
  font-size: 18px;
  color: #542b00;
}

.words_statistic__title
{
  padding-right: 5px;
}

.words_statistic__title.admin,
.words_statistic__meaning.admin
{
  margin-right: 20px;
}

.cards_finish__group
{
  padding: 15px;
}

.cards_finish__group__title
{
  margin-bottom: 10px;
  font-size: 20px;
  color: #0008ff;
}

.cards_finish__group__name_course
{
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 600;
  color: #0008ff;
}

@media all and (max-width: 1px){
  h3.cardTitle{
    font-weight: 500px;
  }

  .content {
    font-weight: 100;
    text-align: center;
  }

  .cards__meaning__group
  {
    justify-content: center;
  }

  .content p
  {
    margin-top: -15%;
    line-height: 1.2em;
  }

  .cardContainer
  {
    /* padding-right: 0 !important;
    padding-left: 0 !important; */
    padding: 0px 25px 0 25px !important;
    height: 550px;
  }
  
  .cards
  {
    height: 565px;
  }

  .cards .front,
  .cards .back 
  {
    height: 520px;
  }

  .cards__words
  {
    font-size: 35px;
    font-weight: bold;
  }

  .cards__meaning__vn
  {
    font-size: 23px;
    padding-top: 10px;
  }

  .cards_example__group
  {
    padding: 15px 10px 15px 10px;
  }

  .cards__meaning__kr
  {
    font-size: 27px;
  }

  .cards__example__kr
  {
    font-size: 19px;
    padding-right: 5px;
  }

  .cards__example__vn
  {
    font-size: 19px;
  }

  .cardContainer .cards .back
  {
    display: flex;
    justify-content: center;
  }
  
  .cards__example__split
  {
    justify-content: center;
  }

  .cardsImg {
    width: 260px;
  }

  .button__group
  {
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
  }  

}

@media all and (max-width : 768px) {
  
  .cards
  {
    width:100%;
    height: 565px;
  }

  .content {
    font-weight: 100;
    text-align: center;
  }

  .cards .back
  {
    flex-direction: column;
  }

  .cards__button-wapper
  {
    padding: 20px 0 0 0;
    margin: 0 15px 0 15px;
    flex-direction: row;
  }
  .secondRow
  {
    margin-top: -9%;
  }
  .well 
  {
  padding: 1%;
  }
    .cardContainer:nth-child(3),.cardContainer:nth-child(4)
  {
    margin-top: 5%;
  }
  h3.cardTitle
  {
    font-weight: 500;     
  }
  .content p
  {
    margin-top: -20%;
    line-height: 1.2em;
  }
  .cardWrapper
  {
    margin-left: 4%;
  }

  .words_statistic__group
  {
    width: calc(100% - 30px);
    margin: 15px;
  }

  .words_statistic__title,
  .words_statistic__meaning
  {
    font-family: 'Noto Serif KR', serif;
    font-size: 13px;
    color: #542b00;
  }

  .cards__example__kr
  {
    font-size: 19px;
  }

}

@media all and (max-width : 600px) {
  .course_name--custom
  {
    font-size: 32px !important;
    padding: 5px !important;
  }

  .main_flashcard
  {
    margin: 15px;
  }

  .cards
  {
    height: 500px;
  }

  .cardContainer
  {
    margin: 10px 0 10px 0;
    padding: 0 7px 0 7px !important;
    height: 490px;
  }

  .cards .front, .cards .back {
    height: 450px;
  }

  .cardsImg {
    flex-shrink: 0;
    /* background-image: linear-gradient(147deg, #000 0%, #000 74%); */
    /* box-shadow: 0px 3px 10px 1px rgb(252 56 56 / 20%); */
    border-radius: 10px;
    overflow: hidden;
    margin: 0 15px 0 15px;
    border: 1px solid rgb(226, 226, 226);
    width: 190px;
    height: auto;
  }

  .cards__button-wapper
  {
    padding: 15px 0 15px 0;
    flex-direction: column;
  }

  .button__group__button
  {
    font-size: 15px;
    margin: 0 !important;
  }

  .cardsImg
  {
    width: auto;
    height: 125px;
  }

  .cards .back
  {
    flex-direction: column;
    justify-content: center;
    /* margin: 20px; */
  }

  .cards__words
  {
    font-size: 25px;
    font-weight: bold;
  }

  .cards_example__group
  {
    padding: 15px 10px 15px 10px;
  }

  .cards__meaning__kr
  {
    font-size: 23px;
  }

  .cards__meaning__vn
  {
    margin-top: 4px;
    font-size: 22px;
  }

  .cards__example__kr
  {
    font-size: 16px;
    padding: 0;
  }

  .cards__example__vn
  {
    font-size: 18px;
  }

  .content {
    font-weight: 100;
    text-align: center;
  }

  .cards__example__split
  {
    display: block;
    padding-bottom: 10px;
  }

  #button-cus {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    margin: auto;
    z-index: 1000;
  }
  
  .button-cus {
    border: none;
    border-radius: 10px;
    width: 48px !important;
    height: 45px !important;
    cursor: pointer;
    margin: 0 10px 7px 10px !important;
  }

  #button-cus-group
  {
    display: flex;
  }

  .button-cus-option
  {
    
    margin: 10px 0px 10px 0 !important;
  }

  .button__group__button
  {
    font-size: 15px;
  }

  .logo img {
    top: 25px !important;
    right: -25px !important;
    width: 50px !important;
  }
 
  .cards__subtitle
  {
    font-size: 25px !important;
    padding-bottom: 5px;
  }

}

#button-cus-group
{
  display: flex;
  justify-content: center;
}

#button-cus {
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.button-cus {
  border: none;
  border-radius: 10px;
  width: 60px;
  height: 50px;
  margin: 0 20px;
  cursor: pointer;
  margin-top: 20px;
}

.button-cus--shuffer
{
  background-color: var(--success);
}

.button-cus--restart
{
  background-color: var(--warning);
}

.button-cus:hover
{
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
}

.tooltiptext 
{
  text-align: center;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  margin-top: auto;
  background-color: #AFD1FF;
  color: navy;
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.button-cus span {
  font-size: 30px;
}

.main_flashcard
{
  background-color: #fff;
  /* background-color: #293749; */
  border-radius: 30px;
  /* padding: 15px; */
}

.course_name--custom
{
  /* font-family: "DynaPuff", cursive; */
  font-family: 'Itim', cursive;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #293749;
  padding: 20px 0 20px 0;
  font-weight: 600;
}

.progress--custom
{
  border-radius: 2px !important;
  background-color: #fff !important;
  font-size: 11px !important;
  height: 17px !important;
  font-weight: 700 !important;
}

.logo--custom
{
  display: block;
  width: 50px;
  height: 50px;
  background-color: #333;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tuvungtienghanthaytu-2f56b.appspot.com/o/courses%2Ft%E1%BA%A3i_xu%E1%BB%91ng-removebg-preview%20(1).png?alt=media&token=d249e283-a4ea-405d-8daa-63be9dbf1f85");
}

.center-elements{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100%;
  float: right;
  text-align: center;

}
.logo img {
  top: 40px;
  right: -30px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 65px;
}

/* Text Shadow */

.text_with_1px_border
{
  text-shadow: 
    -1px -1px 0px #000,
      0px -1px 0px #000,
      1px -1px 0px #000,
    -1px  0px 0px #000,
      1px  0px 0px #000,
    -1px  1px 0px #000,
      0px  1px 0px #000,
      1px  1px 0px #000;
}

.text_with_2px_border
{
  text-shadow: 
    /* first layer at 1px */
    -1px -1px 0px #000,
      0px -1px 0px #000,
      1px -1px 0px #000,
    -1px  0px 0px #000,
      1px  0px 0px #000,
    -1px  1px 0px #000,
      0px  1px 0px #000,
      1px  1px 0px #000,
    /* second layer at 2px */
    -2px -2px 0px #000,
    -1px -2px 0px #000,
      0px -2px 0px #000,
      1px -2px 0px #000,
      2px -2px 0px #000,
      2px -1px 0px #000,
      2px  0px 0px #000,
      2px  1px 0px #000,
      2px  2px 0px #000,
      1px  2px 0px #000,
      0px  2px 0px #000,
    -1px  2px 0px #000,
    -2px  2px 0px #000,
    -2px  1px 0px #000,
    -2px  0px 0px #000,
    -2px -1px 0px #000;
}

.text_with_2px_border_blue
{
  text-shadow:
    -2px   -2px lightblue,
    -2px -1.5px lightblue,
    -2px   -1px lightblue,
    -2px -0.5px lightblue,
    -2px    0px lightblue,
    -2px  0.5px lightblue,
    -2px    1px lightblue,
    -2px  1.5px lightblue,
    -2px    2px lightblue,
    -1.5px  2px lightblue,
    -1px    2px lightblue,
    -0.5px  2px lightblue,
    0px     2px lightblue,
    0.5px   2px lightblue,
    1px     2px lightblue,
    1.5px   2px lightblue,
    2px     2px lightblue,
    2px   1.5px lightblue,
    2px     1px lightblue,
    2px   0.5px lightblue,
    2px     0px lightblue,
    2px  -0.5px lightblue,
    2px    -1px lightblue,
    2px  -1.5px lightblue,
    2px    -2px lightblue,
    1.5px  -2px lightblue,
    1px    -2px lightblue,
    0.5px  -2px lightblue,
    0px    -2px lightblue,
    -0.5px -2px lightblue,
    -1px   -2px lightblue,
    -1.5px -2px lightblue;
}

.text_with_1px_normal
{
  text-shadow: 0 0 1px rgb(230, 230, 230), 0 0 1px rgb(230, 230, 230), 0 0 1px rgb(230, 230, 230), 0 0 1px rgb(230, 230, 230);
}

.front_bg_monday
{
  background: #fff url("https://wallpaperaccess.com/full/4086256.jpg") no-repeat center;
  background-size: cover;
  
}

.back_bg_monday
{
  background: #fff url("https://wallpaperaccess.com/full/4086256.jpg") no-repeat center;
  background-size: cover;
  
}

.front_bg_tuesday
{
  background: #fff url("https://wallart.vn/Portals/37/DNNGo_Combobox/64349/0/Gi%E1%BA%A5y%20d%C3%A1n%20t%C6%B0%E1%BB%9Dng%20tr%C6%A1n%20m%C3%A0u%20v%C3%A0ng%20nh%E1%BA%A1t%20%207906-2.jpg") no-repeat center;
  background-size: cover;
}

.back_bg_tuesday
{
  background: #fff url("https://wallart.vn/Portals/37/DNNGo_Combobox/64349/0/Gi%E1%BA%A5y%20d%C3%A1n%20t%C6%B0%E1%BB%9Dng%20tr%C6%A1n%20m%C3%A0u%20v%C3%A0ng%20nh%E1%BA%A1t%20%207906-2.jpg") no-repeat center;
  background-size: cover;
}

.front_bg_wednesday
{
  background: #fff url("https://img.freepik.com/free-vector/neutral-soft-abstract-watercolor-background-vector_53876-140748.jpg?w=2000") no-repeat center;
  background-size: cover;
}

.back_bg_wednesday
{
  background: #fff url("https://img.freepik.com/free-vector/neutral-soft-abstract-watercolor-background-vector_53876-140748.jpg?w=2000") no-repeat center;
  background-size: cover;
}

.front_bg_thursday
{
  background: #fff url("https://wallpaper-mania.com/wp-content/uploads/2018/09/High_resolution_wallpaper_background_ID_77700451514.jpg") no-repeat center;
  background-size: cover;
}

.back_bg_thursday
{
  background: #fff url("https://wallpaper-mania.com/wp-content/uploads/2018/09/High_resolution_wallpaper_background_ID_77700451514.jpg") no-repeat center;
  background-size: cover;
}

.front_bg_friday
{
  background: #fff url("https://wallart.vn/Portals/37/DNNGo_Combobox/64349/0/Gi%E1%BA%A5y%20d%C3%A1n%20t%C6%B0%E1%BB%9Dng%20tr%C6%A1n%20m%C3%A0u%20v%C3%A0ng%20nh%E1%BA%A1t%20%207906-2.jpg") no-repeat center;
  background-size: cover;
}

.back_bg_friday
{
  background: #fff url("https://wallart.vn/Portals/37/DNNGo_Combobox/64349/0/Gi%E1%BA%A5y%20d%C3%A1n%20t%C6%B0%E1%BB%9Dng%20tr%C6%A1n%20m%C3%A0u%20v%C3%A0ng%20nh%E1%BA%A1t%20%207906-2.jpg") no-repeat center;
  background-size: cover;
}

.front_bg_saturday
{
  background: #fff url("https://wallpaperaccess.com/full/4086256.jpg") no-repeat center;
  background-size: cover;
}

.back_bg_saturday
{
  background: #fff url("https://wallpaperaccess.com/full/4086256.jpg") no-repeat center;
  background-size: cover;
}

.front_bg_sunday
{
  background: #fff url("https://wallpaperaccess.com/full/4086256.jpg") no-repeat center;
  background-size: cover;
}

.back_bg_sunday
{
  background: #fff url("https://wallpaperaccess.com/full/4086256.jpg") no-repeat center;
  background-size: cover;
}
