:root {
    /* color palettes */
    --primary-color: #018c4c;
    --secondary-color: #2ac07c;
    --accent-color: #ca3600;
  
    --bg-color-main: #fff;
    --bg-color-sec: #fcfcfc;
    --bg-color-accent: #f1f1f1;
  
    --title-color: #1e1e1e;
    --text-color: rgb(22, 99, 199);
  
    --label-color: #6e6e6e;
    --border-color: rgba(0, 0, 0, 0.15);
    --hover-color: #e5e5e5;
  
    --phonetic-color: #6200ee;
  
    /* common color */
    --light-grey: #e5e5e5;
    --grey: #888888;
    --medium-grey: #5e5e5e;
    --dark-grey: #333333;
    --error-color: #d6493c;
    --right-color: #29a322;
  
    /* global variable */
    --font: 'Montserrat', Arial, Helvetica, sans-serif;
    --nav-height: 7.2rem;
    --sm-border-radius: 4px;
    --border-radius: 6px;
    --box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 1px 12px 4px rgb(0 0 0 / 8%);
    --box-shadow-2: 0px 2px 1px -1px rgb(0 0 0 / 18%), 0px 1px 6px 0px rgb(0 0 0 / 12%), 0px 1px 4px 0px rgb(0 0 0 / 8%);
  }
  
  [data-theme='dark'] {
    --primary-color: #02b262;
    --secondary-color: #38d990;
    --accent-color: #ff4703;
  
    --bg-color-main: #22272e;
    --bg-color-sec: #2d333b;
    --bg-color-accent: #373e46;
  
    --title-color: #eeb655;
    --text-color: #ffffff;
    --hover-color: #4e5763;
  
    --label-color: #dedede;
    --border-color: rgba(255, 255, 255, 0.15);
    --phonetic-color: #c7a0f7;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
    font-size: 80%;
    width: 100%;
    height: 100%;
    font-family: var(--font);
  }
  
  body {
    position: relative;
    background-color: var(--bg-color-main);
    overflow: auto;
  }
  
  #root {
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: var(--bg-color-main);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--medium-grey);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--grey);
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  p,
  ul {
    margin: 0;
    color: var(--text-color);
  }
  
  ul {
    list-style: none;
  }
  
  p {
    word-wrap: break-word;
  }
  
  li {
    text-decoration: none;
    list-style-type: none;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    text-decoration: none;
  }
  
  @media only screen and (max-width: 576px) {
    html {
      font-size: 75%;
    }
  }
  