// spin
.ani-spin {
    -webkit-animation-name: spinAni;
    animation-name: spinAni;
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  
  @-webkit-keyframes spinAni {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinAni {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  // fade
  .ani-fade {
    -webkit-animation: fadeAni 0.45s ease-in-out;
    animation: fadeAni 0.45s ease-in-out;
  }
  
  @-webkit-keyframes fadeAni {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeAni {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  // collapse
  .ani-zoom {
    -webkit-animation: zoom 0.35s linear forwards;
    animation: zoom 0.35s linear forwards;
  }
  
  @-webkit-keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: none;
    }
  }
  
  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: none;
    }
  }
  