:root {
  --header: #ffaaa7;
  --font: #435560;
  --flashcard: #98ddca;
  --body: #fdf6f0;
  --lang1: #d4f0bc;
  --lang2: #ffd3b4;
  --lang3: #faf4b7;
  --lang1-hover: #c7fa9e;
  --lang2-hover: #ffc9a3;
  --lang3-hover: #fdf59b;

}


@font-face {
	font-family: "BatangBold";
	src: local("BatangBold"), url("./fonts/Batang.ttf") format ("truetype");
}


.navbar-custom
{
  background-color: rgb(255 187 0);
}

.navbarBrand--custom
{
  font-size: 23px !important;
  font-weight: 700 !important;
  color: rgb(41 55 73) !important;
}

.navLink--custom
{
  font-size: 20px !important;
  font-weight: 600 !important;
  color: rgb(41 55 73) !important;
}


.welcome-card{
  font-weight: 600;
  width: 100%;
  padding: 20px;
  height: auto;
  border: none !important;
  box-shadow: 1px 4px 12px rgba(3, 0, 159, 0.1);
}
.welcome-card > h2{
  color: #4f4f4f;
  font-family: 'Source Sans Pro';
}
.username{
  color: #03009F;
  font-family: 'Source Sans Pro';
  font-size: 35px;
  font-weight: 550;
  margin: 0 5px;
}

.class-helper{
  color: #4f4f4f;
  text-align: center;
}
.class-link{
  color: white;
  text-decoration: none !important;
}
.class-link:hover{
  color: white;
}
.class-card{
  font-family: 'Source Sans Pro';
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border: none !important;
  padding: 20px;
}
.class-btn{
  text-transform: uppercase;
  background: #219653 !important;
  font-weight: 550 !important;
  border: none !important;
}
.table-title{
  color: #03009F;
  font-weight: 550;
}
.suspend-button{
  width: 100%;
  font-weight: 550 !important;
}
.basic-info{
  padding: 20px;
  background: #FFFFFF;
  border: none !important;
  box-shadow: -1px 10px 19px rgba(3, 0, 159, 0.1);
}

.basic-info > h4{
  color: #2F80ED;
  font-weight: 550;
}

.info-label{
  color: #4F4F4F;
  font-weight: 500;
  font-size: 18px;
}

.s-name{
  color: black;
  font-weight: 550;
}

.selector{
  cursor:  pointer !important;
}

.toast{
  margin: 5px !important;
  border-radius: 0px !important;
  border: none !important;
  position: relative !important;
  float: right !important;
  font-weight: 550 !important;
  width: 200px !important;
  font-size: 18px !important;
}

.errorToast{
  border-left: red 3px solid !important; 
  margin: auto !important;
  float: left !important;
  font-size: 14px !important;
}

.successToast{
  border-left: #32B6A2 3px solid !important; 
}

.upload-wrapper{
  padding: 5px;
  border-radius: 50px;
  border: none;
  background: transparent;
  z-index: 1;
  position: relative;
  top: 100px;
  right: 5%;
}

.img{
  width: 150px !important;
  object-fit: cover;
  
}
.camera-icon{
  background:white;
  width:40px;
  height:40px;
  border-radius:50%;
  display:flex;
  justify-content: center;
  position:absolute;
  bottom:5px;
  right:-20px;
  z-index: 1;
  padding: 10px;
  cursor: pointer;
}

.button{
  border: none !important;
  font-weight: 550 !important;
  text-transform: uppercase;
  width: 80%;
  margin-top: auto;
  font-size: 14px !important;
}

.card-button{
  border: none !important;
  font-weight: 550 !important;
  text-transform: uppercase;
  width: auto;
  margin-top: 10px;
  font-size: 14px !important;
}

.course-card{
  margin: 5px;
  border: none !important;
  box-shadow: -2px 5px 18px rgba(3, 0, 159, 0.15);
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}


.course-card:hover{
  transform: scale(1.05);
}


.navy{
  background-color: #0021CA !important;
}

.subtitle{
  color: black !important;
  font-weight: 500;
}


.profile-menu{
  margin-right: 10px !important;
}

.profile-dropdown{
  font-family: 'Source Sans Pro';
  font-weight: 550 !important;
  background-color: transparent !important;
  margin: 0 !important;
  border: 1px solid !important;
  font-weight: 600 !important;
  color: rgb(41 55 73) !important;
  font-size: 20px !important;
}
.profile-dropdown:active{
  background-color: transparent !important;
  outline: none !important;
}

.profile-dropdown:focus{
  background-color: transparent !important;
  outline: none !important;
}

/* .navbar{
  background: #AFD1FF !important;
} */

.slider-img{
  width:100%;
  height:400px;
}
.carousel{
  width:100%;
}

@media (max-width: 767px) {    

}

.question{
  color: navy;
  font-size: 18px;
  font-family: 'Source Sans Pro', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.title{
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 550;
  color: darkblue;
}
.user{
  font-size: 16px;
  font-weight:500;
}

.q-card{
  box-shadow: 1px 4px 12px rgba(3, 0, 159, 0.1);
  border: none !important;
}

.footer{
  text-align: center;
  font-size: 16px; 
  font-family: 'Source Sans Pro';
  margin-top: auto;
  background-color: #AFD1FF;
  color: navy; 
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 9999999;
}

.notif-heading{
  background: #03009F !important;
  font-family: 'Source Sans Pro';
  font-weight: 550;
  text-align: center;
  color: white;
  border: none;
}

.add-notif{
  width: 100%;
  font-family: 'Source Sans Pro';
  text-transform: uppercase;
  font-weight: 550 !important;
}

.notifications{
  border: none;
  box-shadow: 0px 4px 4px rgba(3, 0, 159, 0.2);
}
.link{
  color: white !important;
  text-decoration: none !important;
}
.course-title{
  color: #4F4F4F;
}

.c-title{
  color: black;
  font-weight: 550;
}

.video-title{
  font-size: 20px;
  font-weight: 550;
}

.video-card{
  margin: 0px 5px;
  padding: 10px;
}

.play-button{
  background: navy !important;
}
.view-button{
  background:royalblue !important;
}

.card-link{
  text-decoration: none;
  color: black;
}

.a-card{
  margin: 5px;
  border: none !important;
  box-shadow: -2px 5px 18px rgba(3, 0, 159, 0.15);
}

.a-title{
  color:black;
  font-size: 20px;
  font-weight: 550;
}

.edit{
  background:crimson !important;
}

.a-btn{
  border: none !important;
}

.view{
  background: #03009F !important;
}

.a-subtitle{
  font-size: 16px !important;
  margin: 10px 0px;
  font-weight: 550;
  color: darkslategrey !important;
}

.comment-user{
  color: #2F80ED;
  font-weight: 550;
}
.answers{
  color: #03009F;
  font-weight: 600;
}

.comment{
  border-radius: 0% !important;
  width: auto;
  position: relative;
  margin: 10px;
}

.comment-answer{
  color: black;
  font-weight: 600;
}

.question-card{
  width: auto;
  padding: 10px;
  height: auto;
}
.comment-section{
  padding: 10px;
  background: #F5F5F5;
  border: 1px solid lightgray;
  width: auto;
  height: auto;
}
.commenter{
  color: #03009F;
  font-weight: 550;
}

.comments{
  width: auto;
  padding: 10px;
  border: 1px solid lightgray;
  background-color: white;
}
.user{
  color: crimson;
  font-weight: 600;
}
.forum-question{
  color: darkblue;
  font-size: 25px;
  font-weight: 600;
}

.topic{
  width: auto;
  background-color:transparent !important;
  color: #219653 !important;
  border: #219653 1px solid; 
}

.remove-question{
  cursor: pointer;
  color: red;
  font-weight: 550 !important;
  font-size: 12px !important;
}

.empty-div{
  border-radius: 10px;
  border: 0.1px solid #dae4ee;
  padding: 100px;
  width: 100%;
}
.center-text{
  text-align: center;
}


.signup-box{
  height: 95vh;
  position:relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image:  url('./Assets/kit_background_3.png');
}

.error{
  color: orangered;
  margin: 8px 0px;
  font-weight: 550 !important;
  font-size: 16px !important;
}

.logo-container{
  width: 100%;
  height: 100px;
  overflow: hidden;
  z-index: 0;
  background-color: #2D9CDB;
  position: absolute;
  top: 40%;
  text-align: center;
}

.logo{
  margin-top: 10px;
}

.signup-container{
  font-family: 'Source Sans Pro' !important;
  font-size: 18px;
  font-weight: 600;
}

.signup-button{
  margin: 10px 0px;
  font-weight: 600 !important;
  width: 20%;
}

.logo-mobile{
  display: none;
}

.login-button{
  width: 20%;
}

.heading{
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #03009F;
  font-style: normal;
  font-weight: bold;
}

.login-helper{
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 500 !important;
}

.login-helper  > a {
  cursor: pointer;
  color:#2D9CDB !important;
  font-weight: 600;
}



@media (max-width: 767px) {    
  .signup-box{
    height: auto;
    display: flex;
    background: none;
  }
  .signup-container{
    
    margin-bottom: 50px;
  }

  .slider-img{
    width:100%;
    height:200px;
  }
}

/* Card custom */

.course-card__custom {
  background: linear-gradient(147deg, rgb(188, 250, 180) 0%, rgb(196 255 200) 50%) !important;
  box-shadow: rgb(183 183 183) 0px 1px 0px;
}

.card-header__custom
{
  background: linear-gradient(147deg, rgb(188, 250, 180) 0%, rgb(196 255 200) 50%) !important;
  font-size: 1.35rem;
}

.card-button__custom
{
  width: 100%;
  color: #fff;
  border-radius: 9px;
  text-align: center;
  /* line-height: 42px; */
  font-size: 15px;
  margin: 12px 0px 0px;
  border-color: none !important;
}

.card-button__custom.continue
{
  background: #FF9600 !important;
}

.card-button__custom.new
{
  background: #5C6CFC !important;
}

.card-container__custom
{
  padding-bottom: 10rem !important;
}

/* home */

.home_course__group
{
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  min-height: 80px;
  padding: 33px 30px;
  margin: 15px 15px 15px 0;
  box-shadow: rgb(188 194 207) 0px 2px 0px;
}

.home_course__group h5 {
  color: #0021CA;
  font-weight: 500;
}

.modal_image__custom
{
  margin: 3px;
  border: 1px solid rgb(255, 221, 221);
}

.modal_image__custom.active
{
  border: 2px solid #333;
}

.course_form__edit_group
{
  display: flex;
  justify-content: space-between;
  background-color: #fdfdfd;
  width: 100%;
  padding: 15px;
}


@media all and (max-width: 1000px)
{
  .course-card__custom {
    padding: 0.5rem !important;
  }

  .card-header__custom
  {
    padding: 0.75rem 0.75rem !important;
  }

  .card-body__custom
  {
    padding: 0.5rem !important;
  }
  
}
